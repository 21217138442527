import { useContext, useEffect, useState } from 'react';
import CustomModal from '../../../ui-kit/custom-modal/custom-modal.component';
import Button from '../../../ui-kit/button/button';
import ApplicationContext from '../../../context/application-context';
import { APP_LOCATIONS } from '../../../routes/routes';
import './acknowledgement.style.scss';

interface AcknowledgementProps {
    showAcknowledgementModal: boolean;
    isEnrollPushNotif?: boolean
}

const PNAcknowledgement = (p: AcknowledgementProps) => {

    const context = useContext(ApplicationContext);
    let notificationChecked = context.Security.getNotificationChecked();


    const [showModal, setShowModal] = useState(p.showAcknowledgementModal)

    let unEnrollPushNotif = context.Security.getUnEnrollPushNotif();


    const handleFooterClick = () => {
        context.Security.setUnEnrollPushNotif(true);
        if (p.isEnrollPushNotif) {
            context.DEVICE.goToNativePage(APP_LOCATIONS.MfaHome);
        }
        setShowModal(false)
    }

    const handleEnrollClick = () => {
        if(notificationChecked){
            context.Security.setUnEnrollPushNotif(true);
        }
        else{
            context.Security.setUnEnrollPushNotif(false);
        }
        if (!unEnrollPushNotif) {
            if (p.isEnrollPushNotif) {
                context.Router.navigate(APP_LOCATIONS.MfaPushNotifEnroll, { notifications: true });
            }
            else {
                context.Router.navigate(APP_LOCATIONS.PushNotifLanding, { notifications: true });
            }
        }
    }

    const renderTitle = () => {
        return <>
            <div className='pn-header'>
                <p>Enroll for Push Notifications</p>
            </div>

        </>
    }

    const renderBody = () => {
        return <>
            <p>
                When you Sign In to your account using Face ID, you don’t need to enter your password/credentials. Anyone enrolled into biometrics at the device level will have access to your accounts.

                We don’t store your Face ID information.
            </p>
        </>
    }


    const renderFooter = () => {
        return <>
            <Button
                onClick={handleFooterClick}
                containerView='Push Notifications Enroll'>
                {"Cancel"}
            </Button>
            <Button
                color='primary'
                onClick={handleEnrollClick}
                containerView='Push Notifications Enroll'>
                {"Enroll"}
            </Button>
        </>
    }

    return (
        <CustomModal
            showModal={showModal}
            isCloseable={false}
            wrapperClass={`pn-acknowledgement-modal`}
            title={renderTitle()}
            renderFooter={renderFooter}
            body={renderBody()}
            bodyStyle={{ maxHeight: 'none' }}
        />
    );
};

export default PNAcknowledgement;